.App {
    text-align: center;
    font-family: 'Arial', sans-serif;
    color: #333;
    margin: 20px;
    background-color: #f4f6f8;
}

.App-header {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

h1 {
    color: #2c3e50;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 1.5em; /* Smaller font size for mobile */
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-image: linear-gradient(to right, #6db3f2, #1e69de);
    color: #fff;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Make table scrollable on small screens */
@media (max-width: 600px) {
    table, thead, tbody, th, td, tr {
        display: block;
    }

    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tr {
        border: 1px solid #ccc;
    }

    td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        text-align: right;
    }

    td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
    }

    /* Label the data */
    td:nth-of-type(1):before {
        content: "Exercise";
    }

    td:nth-of-type(2):before {
        content: "Total Completed";
    }

    td:nth-of-type(3):before {
        content: "Percent Complete";
    }

    td:nth-of-type(4):before {
        content: "Yearly Goal";
    }

    td:nth-of-type(5):before {
        content: "Days Missed";
    }

    td:nth-of-type(6):before {
        content: "Completed since 20230101";
    }
}

.chart-container {
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f8f8f8;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-x: auto; /* Allow horizontal scrolling for the chart */
}

/* Responsive font sizes */
@media (max-width: 600px) {
    h1 {
        font-size: 1.2em;
    }
}

.insert-form {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.insert-form label {
    display: block;
    margin-bottom: 10px;
}

.insert-form label span {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.insert-form input[type="number"],
.insert-form input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.insert-form button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #1e69de;
    color: white;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.insert-form button:hover {
    background-color: #6db3f2;
}

.insert-form button:disabled {
    background-color: #aaa;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .insert-form {
        width: 90%;
        margin: 20px auto;
    }
}
